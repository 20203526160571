import * as React from "react"
import HelmetSlot from "../components/Helmet"
import Navigation from "../components/Navigation"
import Footer from "../components/Footer"
import "../theme/main.less"

const NotFoundPage = () => {
  return (
    <>
      <HelmetSlot
        title="404. Diese URL existiert nicht."
        description="Bitte navigieren Sie zur Startseite der Seite zurück oder geben sie eine gültige URL ein."
        noindex
      />
      <main>
        <body className="four-o-four">
          <div className="view">
            <div className="landing">
              <Navigation />
            </div>
            <div className="main">
              <div className="content">
                <h1>Die Seite existiert leider nicht…</h1>
                <p className="text">
                  Entschuldigung, aber die Seite die Sie versucht haben zu
                  erreichen, existiert leider nicht. <br></br>Bitte gehen Sie
                  zurück zur Startseite oder geben Sie eine gültige URL in die
                  Suchleiste ein.
                </p>
                <a href="/" alt="zurück zur startseite">
                  <button>Zurück zur Startseite</button>
                </a>
              </div>
            </div>
            <Footer />
          </div>
        </body>
      </main>
    </>
  )
}

export default NotFoundPage
